import $ from "expose-loader?exposes=$,jQuery!jquery";

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');

const throttle = require('jquery-throttle-debounce');
window.$.throttle = throttle.Cowboy.throttle;
window.$.debounce = throttle.Cowboy.debounce;

require('jquery.cookie');
require('/plugins/spin/jquery.spin')