import {Spinner} from 'spin.js';
import 'spin.js/spin.css'

(function ($) {
    $.fn.spin = function (opts, color) {
        return this.each(function () {
            var $this = $(this),
                data = $this.data();

            if (data.spinner) {
                data.spinner.stop();
                delete data.spinner;
            }
            if (opts !== false) {
                if (typeof opts === "string") {
                    opts = {};
                    if (color) {
                        opts.color = color;
                    }
                }
                data.spinner = new Spinner($.extend({
                    color: $this.css('color')
                }, opts)).spin(this);
            }
        });
    };
})(jQuery);
